<template>
  <div>
    <v-card v-if="editor">
      <v-card-text>
        <div>
          <v-btn
            @click="editor.chain().focus().undo().run()"
            :disabled="!editor.can().chain().focus().undo().run()"
            depressed
            class="mr-2 mb-2"
          >
            <v-icon>mdi-undo</v-icon>
          </v-btn>
          <v-btn
            @click="editor.chain().focus().redo().run()"
            :disabled="!editor.can().chain().focus().redo().run()"
            depressed
            class="mr-2 mb-2"
          >
            <v-icon>mdi-redo</v-icon>
          </v-btn>
          <v-btn @click="clearFormatting()" depressed class="mr-2 mb-2">
            <v-icon left>mdi-format-clear</v-icon>Formatierung löschen
          </v-btn>
        </div>
        <div>
          <v-btn-toggle dense multiple class="mr-2 mb-2">
            <v-btn
              @click="editor.chain().focus().toggleBold().run()"
              :disabled="!editor.can().chain().focus().toggleBold().run()"
              :color="editor.isActive('bold') ? 'blue lighten-4' : ''"
            >
              <v-icon>mdi-format-bold</v-icon>
            </v-btn>
            <v-btn
              @click="editor.chain().focus().toggleItalic().run()"
              :disabled="!editor.can().chain().focus().toggleItalic().run()"
              :color="editor.isActive('italic') ? 'blue lighten-4' : ''"
            >
              <v-icon>mdi-format-italic</v-icon>
            </v-btn>
            <v-btn
              @click="editor.chain().focus().toggleUnderline().run()"
              :disabled="!editor.can().chain().focus().toggleItalic().run()"
              :color="editor.isActive('underline') ? 'blue lighten-4' : ''"
            >
              <v-icon>mdi-format-underline</v-icon>
            </v-btn>
            <v-btn
              @click="editor.chain().focus().toggleStrike().run()"
              :disabled="!editor.can().chain().focus().toggleStrike().run()"
              :color="editor.isActive('strike') ? 'blue lighten-4' : ''"
            >
              <v-icon>mdi-format-strikethrough-variant</v-icon>
            </v-btn>
            <v-btn
              @click="editor.chain().focus().toggleHighlight().run()"
              :disabled="!editor.can().chain().focus().toggleHighlight().run()"
              :color="editor.isActive('highlight') ? 'blue lighten-4' : ''"
            >
              <v-icon>mdi-format-color-highlight</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle dense class="mr-2 mb-2">
            <v-btn
              @click="editor.chain().focus().setTextAlign('left').run()"
              :color="
                editor.isActive({ textAlign: 'left' }) ? 'blue lighten-4' : ''
              "
            >
              <v-icon>mdi-format-align-left</v-icon>
            </v-btn>
            <v-btn
              @click="editor.chain().focus().setTextAlign('center').run()"
              :color="
                editor.isActive({ textAlign: 'center' }) ? 'blue lighten-4' : ''
              "
            >
              <v-icon>mdi-format-align-center</v-icon>
            </v-btn>
            <v-btn
              @click="editor.chain().focus().setTextAlign('right').run()"
              :color="
                editor.isActive({ textAlign: 'right' }) ? 'blue lighten-4' : ''
              "
            >
              <v-icon>mdi-format-align-right</v-icon>
            </v-btn>
            <v-btn
              @click="editor.chain().focus().setTextAlign('justify').run()"
              :color="
                editor.isActive({ textAlign: 'justify' })
                  ? 'blue lighten-4'
                  : ''
              "
            >
              <v-icon>mdi-format-align-justify</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle dense multiple class="mr-2 mb-2">
            <v-btn
              @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
              :color="
                editor.isActive('heading', { level: 1 }) ? 'blue lighten-4' : ''
              "
            >
              <v-icon>mdi-format-header-1</v-icon>
            </v-btn>
            <v-btn
              @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
              :color="
                editor.isActive('heading', { level: 2 }) ? 'blue lighten-4' : ''
              "
            >
              <v-icon>mdi-format-header-2</v-icon>
            </v-btn>
            <v-btn
              @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
              :color="
                editor.isActive('heading', { level: 3 }) ? 'blue lighten-4' : ''
              "
            >
              <v-icon>mdi-format-header-3</v-icon>
            </v-btn>
            <!-- <v-btn
              @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
              :color="editor.isActive('heading', { level: 4 }) ? 'blue lighten-4' : ''"
            >
              <v-icon>mdi-format-header-4</v-icon>
            </v-btn>
            <v-btn
              @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
              :color="editor.isActive('heading', { level: 5 }) ? 'blue lighten-4' : ''"
            >
              <v-icon>mdi-format-header-5</v-icon>
            </v-btn>
            <v-btn
              @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
              :color="editor.isActive('heading', { level: 6 }) ? 'blue lighten-4' : ''"
            >
              <v-icon>mdi-format-header-6</v-icon>
            </v-btn> -->
            <v-btn
              @click="editor.chain().focus().setParagraph().run()"
              :color="editor.isActive('paragraph') ? 'blue lighten-4' : ''"
            >
              <v-icon>mdi-format-paragraph</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn-toggle dense class="mr-2 mb-2">
            <v-btn
              @click="editor.chain().focus().toggleBulletList().run()"
              :color="editor.isActive('bulletList') ? 'blue lighten-4' : ''"
            >
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn
              @click="editor.chain().focus().toggleOrderedList().run()"
              :color="editor.isActive('orderedList') ? 'blue lighten-4' : ''"
              class="mr-2"
            >
              <v-icon>mdi-format-list-numbered</v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>

        <v-btn
          @click="editor.chain().focus().toggleCode().run()"
          :disabled="!editor.can().chain().focus().toggleCode().run()"
          :color="editor.isActive('code') ? 'blue lighten-4' : ''"
          depressed
          class="mr-2"
        >
          <v-icon>mdi-xml</v-icon>
        </v-btn>

        <v-btn
          @click="editor.chain().focus().toggleCodeBlock().run()"
          :color="editor.isActive('codeBlock') ? 'blue lighten-4' : ''"
          depressed
          class="mr-2"
        >
          <v-icon>mdi-code-braces-box</v-icon>
        </v-btn>
        <v-btn
          @click="editor.chain().focus().toggleBlockquote().run()"
          :color="editor.isActive('blockquote') ? 'blue lighten-4' : ''"
          depressed
          class="mr-2"
        >
          <v-icon>mdi-format-quote-close</v-icon>
        </v-btn>
        <!-- <v-btn
          @click="editor.chain().focus().setHorizontalRule().run()"
          depressed
        >
          horizontal rule
        </v-btn>
        <v-btn @click="editor.chain().focus().setHardBreak().run()" depressed>
          hard break
        </v-btn> -->
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <editor-content
          :editor="editor"
          style="border: solid 1px; border-color: rgb(224 224 224)"
          class="mb-0"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import TextAlign from "@tiptap/extension-text-align";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";

export default {
  name: "TipTapEditor",
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      editor: null,
    };
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;
      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return;
      }
      this.editor.commands.setContent(value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Highlight,
        Typography,
        Underline,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
      ],
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML());
        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    });
  },
  methods: {
    clearFormatting() {
      this.editor.chain().focus().unsetAllMarks().run();
      this.editor.chain().focus().clearNodes().run();
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
/* Basic editor styles */
.tiptap {
  > * + * {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    margin-right: 0.75em;
    margin-left: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>
