<template>
  <div>
    <v-list-item
      v-for="news in items"
      :key="news.meta.id"
      :to="
        link
          ? {
              name: 'my-organization-news-details',
              params: {
                organizationId: $route.params.organizationId,
                itemId: news.meta.id,
              },
            }
          : null
      "
      :link="link"
      :dense="dense"
    >
      <v-list-item-content>
        <v-list-item-title class="subtitle-1 font-weight-medium mb-1"
          ><v-chip
            v-if="newsIsNew(news.publishedAt.timestamp)"
            x-small
            color="red lighten-4"
            class="font-weight-medium red--text text--darken-4 mr-2"
            >Neu</v-chip
          >{{ news.title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <div class="font-weight-normal">
            <v-icon small left>mdi-account-circle</v-icon
            ><span>{{ news.publishedBy.displayName }}</span>
          </div>
          <div>
            <v-icon small left>mdi-clock</v-icon
            >{{ timestampConverter(news.publishedAt.timestamp) }}
          </div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "news-list-items",
  props: {
    items: {
      type: Array,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
      required: false,
    },
    link: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    newsIsNew(publishTimestamp) {
      const publishDate = publishTimestamp.toDate();
      const now = new Date();
      const diff = Math.abs(publishDate.getTime() - now.getTime());
      const diffInHours = diff / (1000 * 3600);
      return diffInHours < 48;
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
