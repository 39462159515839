<template>
  <div>
    <NavigationBar
      titel="Neuigkeiten"
      isPreview
      :actions="actions"
    ></NavigationBar>
    <TippCard
      hintID="Meine_Organisation_News_1_V1"
      text="Veröffentlichte Neuigkeiten werden hier angezeigt. Wichtige Informationen können so an einem Ort abgerufen werden."
    >
    </TippCard>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-newspaper" title="Alle Neuigkeiten">
              </Subheader>
              <div class="mt-7"></div>
              <v-data-iterator
                :items="news"
                sort-desc
                sort-by="publishedAt.timestamp"
                :footer-props="footerProps"
                :items-per-page="10"
                no-data-text="Keine Neuigkeiten vorhanden"
              >
                <template v-slot:default="props">
                  <v-card>
                    <v-list>
                      <news-list-items
                        :items="props.items"
                        link
                      ></news-list-items>
                    </v-list>
                  </v-card>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import NewsListItems from "@/components/my-organization/news/NewsListItems.vue";
import { MY_ORGANIZATION_NEWS_create } from "@/data/permission-types.js";
import { MY_ORGANIZATION } from "@/store/modules.js";
import { GET_NEWS } from "@/store/action-types.js";

export default {
  name: "my-organization-news",
  components: {
    NavigationBar,
    Subheader,
    TippCard,
    NewsListItems,
  },
  data() {
    return {
      footerProps: {
        "items-per-page-options": [10, 25, 50, -1],
      },

      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnMultiple",
          permission: `${MY_ORGANIZATION_NEWS_create}`,
          list: [
            {
              title: "Erstellen mit Editor",
              icon: "mdi-plus-circle",
              function: () => {
                this.$router.push({ name: "my-organization-news-new" });
              },
            },
            {
              title: "Erstellen via E-Mail",
              permission: `${MY_ORGANIZATION_NEWS_create}`,
              icon: "mdi-email-fast",
              function: () => {
                this.$router.push({
                  name: "my-organization-news-inbound-email",
                });
              },
            },
          ],
        },
      ],
    };
  },
  computed: {
    news() {
      return this.$store.state.myOrganization.news;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MY_ORGANIZATION}${GET_NEWS}`, {
        organizationId: this.$route.params.organizationId,
        query: {
          onlyOwn: false,
          //   onlyActive: false,
          //   onlyOwn: true,
          //   ownUserGroup: this.$store.state.organization.membership.group.id,
        },
      });
    },
    createItem() {
      this.$router.push({
        name: "my-organization-news-new",
        params: {
          organizationId: this.$route.params.organizationId,
        },
      });
    },
  },
};
</script>
