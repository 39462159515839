<template>
  <div>
    <NavigationBar
      showBackBtn
      titel="Neuigkeiten via E-Mail erstellen"
      isPreview
    ></NavigationBar>
    <v-snackbar v-model="snackbar.model" multi-line timeout="2000"
      >{{ snackbar.text }}
    </v-snackbar>
    <section>
      <v-container v-if="!checkFeatureAvability">
        <v-alert
          type="warning"
          border="left"
          colored-border
          icon="mdi-alert"
          elevation="2"
          class="mt-6"
        >
          <div class="title">Hinweis</div>
          <div class="mt-2">
            Eingehende E-Mails sind für diese Organisation nicht verfügbar.
            Kontaktiere den Administrator, um die Funktion zu aktivieren.
          </div>
        </v-alert>
      </v-container>
      <v-container v-else>
        <v-row justify="center" align="center">
          <v-col cols="12"
            ><v-card>
              <v-card-title>
                Inbound-Adressen als Eingangskanal verwenden
              </v-card-title>
              <v-card-text class="subtitle-1">
                Aus eingehenden E-Mails an die konfigurierten Inbound-Adressen
                werden automatisch neue Neuigkeiten erstellt. Dabei werden die
                Inhalte direkt aus der eingehenden E-Mail übernommen. Aus
                Sicherheitsgründen werden nur E-Mails von bekannten Absendern
                und keine Anhänge verarbeitet.
                <br />
                Abhängig von Deinen Berechtigungen muss die Neuigkeit noch von
                einer Person mit Berechtigung freigegeben werden.
              </v-card-text></v-card
            >
          </v-col>
        </v-row>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <v-data-iterator
              :items="newsInboundEmails"
              item-key="meta.id"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:default="props">
                <v-row>
                  <v-col
                    v-for="item in props.items"
                    :key="item.meta.id"
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-card>
                      <v-card-text>
                        <v-alert
                          v-if="item.isDisabled"
                          type="error"
                          text
                          icon="mdi-alert"
                          class="mb-6"
                        >
                          <div class="title">Inbound-Adresse deaktiviert</div>
                          <div class="mt-2">
                            Aus Sicherheitsgründen wurde diese Inbound-Adresse
                            deaktiviert. Kontaktieren Sie den Support, um die
                            Inbound-Adresse wieder zu aktivieren oder neue
                            Inbound-Adressen zu erstellen.
                          </div>
                        </v-alert>
                        <v-alert
                          v-else
                          type="info"
                          icon="mdi-shield-lock"
                          text
                          class="mb-6"
                        >
                          <div class="title">Sicherheitshinweise</div>
                          <div class="mt-2">
                            <ul>
                              <li>
                                Eingehende Nachrichten werden nur von
                                <code>{{ allowedEmailAdress }}</code>
                                zugelassen.
                              </li>
                              <li>
                                Sende Nachrichten mit mehreren Empfängern nur in
                                Blindkopie (BCC) an die Inbound-Adresse
                              </li>
                              <li>
                                Gib Deine Inbound-Adresse nicht an Dritte
                                weiter.
                              </li>
                              <li>
                                Eingehende Nachrichten werden automatisiert auf
                                schädliche Inhalte geprüft.
                              </li>
                              <li>
                                Anhänge werden nicht gespeichert, lediglich
                                aufgelistet.
                              </li>
                              <li>
                                Eingehende Nachrichten dürfen nicht größer als
                                30 MB sein (inklusive Anhänge).
                              </li>
                            </ul>
                          </div>
                        </v-alert>

                        <v-text-field
                          v-model="item.adress"
                          outlined
                          label="Persönliche Inbound-Adresse"
                          readonly
                          filled
                          prepend-inner-icon="mdi-at"
                        >
                        </v-text-field>
                        <v-btn depressed @click="copyToClipboard(item.adress)"
                          ><v-icon left>mdi-content-copy</v-icon>In die
                          Zwischenablage kopieren</v-btn
                        >
                        <v-btn
                          v-if="!item.isDisabled"
                          class="ml-2"
                          depressed
                          @click="saveContact(item)"
                          ><v-icon left>mdi-card-account-mail</v-icon>Als
                          Kontakt speichern</v-btn
                        >
                        <v-btn
                          v-if="!item.isDisabled"
                          class="ml-2"
                          depressed
                          text
                          color="error"
                          :loading="loadingDeleteInboundEmail"
                          @click="deleteInboundEmail(item.meta.id)"
                        >
                          <v-icon left>mdi-delete</v-icon>Löschen
                        </v-btn>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:no-data>
                <v-card>
                  <v-card-text>
                    Es wurden noch keine persönlichen Inbound-Adressen
                    konfiguriert.
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      depressed
                      color="primary"
                      :loading="loadingRequestInboundEmail"
                      @click="requestInboundEmail"
                      class="ml-2 mb-2"
                    >
                      <v-icon left>mdi-email-newsletter</v-icon>Inbound-Adresse
                      anfordern
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-data-iterator>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { auth, functions } from "@/firebase";
import { MY_ORGANIZATION } from "@/store/modules.js";
import { GET_NEWS_INBOUND_EMAILS } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar";

export default {
  name: "my-organization-news-inbound-emails",
  components: {
    NavigationBar,
  },
  data() {
    return {
      loadingRequestInboundEmail: false,
      loadingDeleteInboundEmail: false,
      loadingFixRequest: false,
      snackbar: {
        model: false,
        text: "",
      },
      feed: {
        url: "",
      },
      allowedEmailAdress: auth.currentUser.email,
    };
  },
  computed: {
    checkFeatureAvability() {
      return this.$store.getters["organization/checkFeatureAvailabilityById"](
        "allowInboundEmails"
      );
    },
    newsInboundEmails() {
      return this.$store.state.myOrganization.newsInboundEmails;
    },
  },
  created() {
    if (this.checkFeatureAvability) {
      this.getNewsInboundEmails();
    }
  },
  methods: {
    getNewsInboundEmails() {
      this.$store.dispatch(`${MY_ORGANIZATION}${GET_NEWS_INBOUND_EMAILS}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    requestInboundEmail() {
      this.loadingRequestInboundEmail = true;
      const callFunction = functions.httpsCallable(
        "emailHandler-requestInboundAdress"
      );
      callFunction({
        organizationId: this.$route.params.organizationId,
        type: "news",
      })
        .then((result) => {
          if (result.data) {
            this.snackbar.text = "Inbound-Adresse wurde generiert";
            this.snackbar.model = true;
            this.getNewsInboundEmails();
            this.loadingRequestInboundEmail = false;
          } else {
            this.snackbar.text = "Fehler: Versuche es später erneut.";
            this.snackbar.model = true;
            this.loadingRequestInboundEmail = false;
          }
        })
        .catch((error) => {
          // handle error
          console.error(error.message);
          console.error(error);
          this.snackbar.model = true;
          this.snackbar.text = error.message;
          this.loadingRequestInboundEmail = false;
        });
    },

    deleteInboundEmail(inboundEmailId) {
      this.loadingDeleteInboundEmail = true;
      const callFunction = functions.httpsCallable(
        "emailHandler-deleteInboundAdress"
      );

      console.log("LOG:", {
        organizationId: this.$route.params.organizationId,
        adressId: inboundEmailId,
      });
      callFunction({
        organizationId: this.$route.params.organizationId,
        adressId: inboundEmailId,
      })
        .then((result) => {
          console.log(result.data);
          if (result.data.success) {
            this.snackbar.text = "Inbound-Adresse wurde gelöscht";
            this.snackbar.model = true;
            this.getNewsInboundEmails();
            this.loadingDeleteInboundEmail = false;
          } else {
            this.snackbar.text = "Fehler: Versuche es später erneut.";
            this.snackbar.model = true;
            this.loadingDeleteInboundEmail = false;
          }
        })
        .catch((err) => {
          // handle error
          console.error(err.errorMsg);
          console.error(err);
          this.snackbar.model = true;
          this.loadingDeleteInboundEmail = false;
        });
    },
    copyToClipboard(link) {
      if (window.clipboardData && window.clipboardData.setData) {
        // IE specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", link);
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
      ) {
        var textarea = document.createElement("textarea");
        textarea.textContent = link;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn("Fehler beim Kopieren in Zwischenablage.", ex);
          this.snackbar.text = "Fehler beim Kopieren in Zwischenablage!";
          this.snackbar.model = true;
          return false;
        } finally {
          document.body.removeChild(textarea);
          this.snackbar.text = "Link erfolgreich in Zwischenablage kopiert!";
          this.snackbar.model = true;
        }
      }
    },
    saveContact(inboundAdress) {
      const organization = this.$store.state.organization.activeOrganization;
      const contact = {
        organization: "Mira One (Inbound) | " + organization.displayName,
        title: "Inbound-Adresse (News)",
        email: inboundAdress.adress,
      };

      const vCardContent = `
BEGIN:VCARD
VERSION:3.0
ORG:${contact.organization}
TITLE:${contact.title}
EMAIL:${contact.email}
END:VCARD
`;

      const blob = new Blob([vCardContent], { type: "text/vcard" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "Mira One – Inbound-Adresse.vcf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
  },
};
</script>

<style></style>
