<template>
  <div>
    <NavigationBar
      titel="Neuigkeit bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="updateItem"
      >
        <news-editor v-if="news" v-model="news" mode="edit"></news-editor>
      </v-form>
    </section>
    <CustomDialog
      v-if="helpers.actionDialog.model"
      @close="toggleActionDialog()"
      title="Neuigkeit bearbeiten"
      text="Aus Sicherheitsgründen müssen Inhalte vor der Veröffentlichung verarbeitet werden. Dies kann einige Minuten dauern. Bitte warte, bis der Vorgang abgeschlossen ist."
      :loading="helpers.actionDialog.loading"
      :btnAction="updateItem"
      :errorMessage="helpers.actionDialog.errorMessage"
      btnText="Verarbeitung starten"
      width="s"
    ></CustomDialog>
  </div>
</template>

<script>
import { functions, db, Timestamp } from "@/firebase";
// import { MY_ORGANIZATION } from "@/store/modules.js";
// import { UPDATE_NEWS } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import NewsEditor from "@/components/my-organization/news/NewsEditor.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";

export default {
  name: "my-organization-news-edit",
  components: {
    NavigationBar,
    NewsEditor,
    CustomDialog,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.toggleActionDialog,
        },
      ],

      helpers: {
        formIsValid: false,
        dataIsLoading: true,
        actionDialog: {
          model: false,
          loading: false,
          errorMessage: "",
        },
      },
      news: {
        title: null,
        content: null,
        priority: 3,
        plannedPublish: false,
        publishedAt: {
          date: null,
          time: null,
        },
        recipients: {
          allowedRecipientsIds: [], // currently always "all"
          allowedPersons: [], // currently always []
          allowedUserGroups: [], // currently always [{ title: "Alle", id: "all"}]
        },
      },
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("news")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.news = doc.data();
            this.news.publishedAt.date = this.convertToDate(
              doc.data().publishedAt.timestamp
            );
            this.news.publishedAt.time = this.convertToTime(
              doc.data().publishedAt.timestamp
            );
            this.news.plannedPublish =
              doc.data().publishedAt.timestamp > new Date();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .then(() => {
          this.helpers.dataIsLoading = false;
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    toggleActionDialog() {
      if (this.helpers.actionDialog.model) {
        this.helpers.actionDialog.model = !this.helpers.actionDialog.model;
      } else {
        this.$refs.form.validate();
        if (this.helpers.formIsValid) {
          this.helpers.actionDialog.model = !this.helpers.actionDialog.model;
        } else {
          alert("Prüfen Deine Eingabe und versuchen es erneut.");
        }
      }
    },
    updateItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.helpers.actionDialog.errorMessage = "";
        this.helpers.actionDialog.loading = true;

        // this.$store.dispatch(`${MY_ORGANIZATION}${UPDATE_NEWS}`, {
        //   organizationId: this.$route.params.organizationId,
        //   itemId: this.$route.params.itemId,

        //   title: this.news.title,
        //   content: {
        //     updatedHtml: this.news.content.html,
        //   },
        //   priority: this.news.priority,
        //   // publishedAt: {
        //   //   timestamp: this.news.plannedPublish
        //   //     ? this.convertToTimestamp(
        //   //         this.news.publishedAt.date,
        //   //         this.news.publishedAt.time
        //   //       )
        //   //     : "now",
        //   // },
        //   type: "editor",
        //   recipients: {
        //     allowedRecipientsIds: this.news.recipients.allowedRecipientsIds, // currently always "all"
        //     allowedPersons: this.news.recipients.allowedPersons, // currently always []
        //     allowedUserGroups: this.news.recipients.allowedUserGroups, // currently always [{ title: "Alle", id: "all"}]
        //   },
        // });

        const callFunction = functions.httpsCallable(
          "myOrganization-updateNews"
        );
        return callFunction({
          organizationId: this.$route.params.organizationId,

          news: {
            id: this.$route.params.itemId,
            title: this.news.title,
            priority: this.news.priority,
            content: {
              html: this.news.content.html,
              type: "html", // "html" or "plain-text"
            },
            attachments: [],
            inputType: "editor", // "external" or "in-app"
            status: "published", // "published" or "draft"
            publishedAt: {
              timestamp: this.news.plannedPublish
                ? this.convertToTimestamp(
                    this.news.publishedAt.date,
                    this.news.publishedAt.time
                  )
                : null,
            },
            recipients: {
              allowedRecipientsIds: this.news.recipients.allowedRecipientsIds, // currently always "all"
              allowedPersons: this.news.recipients.allowedPersons, // currently always []
              allowedUserGroups: this.news.recipients.allowedUserGroups, // currently always [{ title: "Alle", id: "all"}]
            },
          },
        })
          .then(() => {
            this.$router.push({
              name: "my-organization-news",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          })
          .catch((error) => {
            this.helpers.actionDialog.errorMessage = error.message;
            this.helpers.actionDialog.loading = false;
            console.error("Error creating news: ", error);
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    convertToDate(date) {
      date = date.toDate();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    },
    convertToTime(time) {
      time = time.toDate();
      return (
        (time.getHours() < 10 ? "0" : "") +
        time.getHours() +
        ":" +
        (time.getMinutes() < 10 ? "0" : "") +
        time.getMinutes()
      );
    },
  },
};
</script>
