var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.items),function(news){return _c('v-list-item',{key:news.meta.id,attrs:{"to":_vm.link
        ? {
            name: 'my-organization-news-details',
            params: {
              organizationId: _vm.$route.params.organizationId,
              itemId: news.meta.id,
            },
          }
        : null,"link":_vm.link,"dense":_vm.dense}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-medium mb-1"},[(_vm.newsIsNew(news.publishedAt.timestamp))?_c('v-chip',{staticClass:"font-weight-medium red--text text--darken-4 mr-2",attrs:{"x-small":"","color":"red lighten-4"}},[_vm._v("Neu")]):_vm._e(),_vm._v(_vm._s(news.title)+" ")],1),_c('v-list-item-subtitle',[_c('div',{staticClass:"font-weight-normal"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-circle")]),_c('span',[_vm._v(_vm._s(news.publishedBy.displayName))])],1),_c('div',[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-clock")]),_vm._v(_vm._s(_vm.timestampConverter(news.publishedAt.timestamp))+" ")],1)])],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }