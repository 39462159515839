<template>
  <div>
    <template>
      <v-container>
        <v-col cols="12">
          <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
          <div class="mt-7">
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="newsLocal.title"
                        label="Titel"
                        required
                        hide-details="auto"
                        :rules="[rules.required]"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="mode === 'new'">
                      <v-checkbox
                        v-model="newsLocal.plannedPublish"
                        label="Veröffentlichung für die Zukunft planen"
                        persistent-hint
                        hint="Der Beitrag wird Mitgliedern der Organisation erst ab dem Veröffentlichungszeitpunkt angezeigt, ist über den Link jedoch bereits vorher abrufbar."
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      v-if="mode === 'edit' || newsLocal.plannedPublish"
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="newsLocal.publishedAt.date"
                        type="date"
                        label="Datum der Veröffentlichung"
                        hint="DD.MM.YYYY"
                        outlined
                        :disabled="mode === 'edit' || !newsLocal.plannedPublish"
                        :rules="[rules.requiredIfPlannedPublish]"
                        required
                        prepend-inner-icon="mdi-calendar-end"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="
                        newsLocal.plannedPublish || newsLocal.publishedAt.time
                      "
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="newsLocal.publishedAt.time"
                        label="Zeit der Veröffentlichung"
                        hint="HH:MM"
                        outlined
                        :disabled="mode === 'edit' || !newsLocal.plannedPublish"
                        :rules="[rules.requiredIfPlannedPublish]"
                        type="time"
                        prepend-inner-icon="mdi-clock-end"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="newsLocal.recipients.allowedUserGroups"
                        :items="userGroups"
                        disabled
                        chips
                        small-chips
                        deletable-chips
                        outlined
                        return-object
                        label="Benutzergruppen auswählen"
                        :rules="[rules.attendanceLimitation]"
                        item-text="title"
                        item-value="id"
                        multiple
                        clearable
                        hide-details="auto"
                        append-inner-icon="mdi-infinity"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="newsLocal.recipients.allowedPersons"
                        :items="membershipsListFiltered"
                        disabled
                        chips
                        small-chips
                        :rules="[rules.attendanceLimitation]"
                        outlined
                        deletable-chips
                        return-object
                        label="Personen auswählen"
                        item-text="displayName"
                        item-value="uid"
                        multiple
                        clearable
                        hide-details="auto"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row> -->
                </v-container>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12">
          <Subheader icon="mdi-text-box-edit" title="Inhalt"></Subheader>
          <div class="mt-7">
            <tip-tap-editor v-model="newsLocal.content.html" />
          </div>
        </v-col>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import TipTapEditor from "@/components/_systemwide/TipTapEditor.vue";
export default {
  name: "news-editor",
  props: {
    news: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
    TipTapEditor,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        requiredIfPlannedPublish: (value) =>
          this.newsLocal.plannedPublish
            ? !!value || "Feld ist erforderlich."
            : true,
      },
    };
  },
  model: {
    prop: "news",
    event: "newsChange",
  },
  computed: {
    newsLocal: {
      get: function () {
        return this.news;
      },
      set: function (value) {
        this.$emit("newsChange", value);
      },
    },
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
    userGroups() {
      const userGroups = this.$store.state.admin.userGroups.map((obj) => ({
        title: obj.title,
        id: obj.meta.id,
      }));
      userGroups.unshift({ title: "Alle", id: "all" });
      return userGroups;
    },
    membershipsListFiltered() {
      const input = this.memberships;
      const groupItems = (arr) => {
        return arr.reduce((a, c) => {
          if (typeof a[c.group.title] === "undefined") a[c.group.title] = [];
          a[c.group.title] = [...a[c.group.title], c];
          return a;
        }, {});
      };
      const step1 = groupItems(input);
      const insertHeaders = (obj) => {
        return Object.entries(obj).reduce((a, [key, vals], i, d) => {
          let r = [
            {
              header: key,
            },
            ...vals,
          ];
          // this is needed to have the exact same array
          // that you presented as desirable output:
          // no divider at the last place
          if (i < d.length - 1) {
            r = [
              ...r,
              {
                divider: true,
              },
            ];
          }
          a = [...a, ...r];
          return a;
        }, []);
      };
      const step2 = insertHeaders(step1);
      const step3 = step2.map((item) => {
        if (item.header) {
          // Skip headers and dividers
          return item;
        } else {
          // Extract displayName and uid from membership object
          return { displayName: item.user.displayName, uid: item.user.uid };
        }
      });

      return step3;
    },
  },
};
</script>
