<template>
  <div>
    <NavigationBar
      titel="Neue Neuigkeit erstellen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="createItem"
      >
        <news-editor v-model="news" mode="new"></news-editor>
      </v-form>
    </section>
    <CustomDialog
      v-if="helpers.actionDialog.model"
      @close="toggleActionDialog()"
      title="Neuigkeit erstellen"
      text="Aus Sicherheitsgründen müssen Inhalte vor der Veröffentlichung verarbeitet werden. Dies kann einige Minuten dauern. Bitte warte, bis der Vorgang abgeschlossen ist."
      :loading="helpers.actionDialog.loading"
      :btnAction="createItem"
      :errorMessage="helpers.actionDialog.errorMessage"
      btnText="Verarbeitung starten"
      width="s"
    ></CustomDialog>
  </div>
</template>

<script>
import { auth, functions, Timestamp } from "@/firebase";
// import { MY_ORGANIZATION } from "@/store/modules.js";
// import { CREATE_NEWS } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import NewsEditor from "@/components/my-organization/news/NewsEditor.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";

export default {
  name: "my-organization-news-new",
  components: {
    NavigationBar,
    NewsEditor,
    CustomDialog,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.toggleActionDialog,
        },
      ],

      helpers: {
        formIsValid: false,
        actionDialog: {
          model: false,
          loading: false,
          errorMessage: "",
        },
      },
      news: {
        title: null,
        content: {
          html: null,
        },
        priority: 3,
        plannedPublish: false,
        publishedAt: {
          date: null,
          time: null,
        },
        recipients: {
          allowedRecipientsIds: ["all"], // currently always "all"
          allowedPersons: [], // currently always []
          allowedUserGroups: [
            {
              title: "Alle",
              id: "all",
            },
          ], // currently always [{ title: "Alle", id: "all"}]
        },
      },
    };
  },
  methods: {
    toggleActionDialog() {
      if (this.helpers.actionDialog.model) {
        this.helpers.actionDialog.model = !this.helpers.actionDialog.model;
      } else {
        this.$refs.form.validate();
        if (this.helpers.formIsValid) {
          this.helpers.actionDialog.model = !this.helpers.actionDialog.model;
        } else {
          alert("Prüfen Deine Eingabe und versuchen es erneut.");
        }
      }
    },
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.helpers.actionDialog.errorMessage = "";
        this.helpers.actionDialog.loading = true;
        // this.$store
        //   .dispatch(`${MY_ORGANIZATION}${CREATE_NEWS}`, {
        //     organizationId: this.$route.params.organizationId,
        //     title: this.news.title,
        //     content: {
        //       html: this.news.content.html,
        //     },
        //     priority: this.news.priority,
        //     publishedAt: {
        //       timestamp: this.news.plannedPublish
        //         ? this.convertToTimestamp(
        //             this.news.publishedAt.date,
        //             this.news.publishedAt.time
        //           )
        //         : "now",
        //     },
        //     type: "editor",
        //     recipients: {
        //       allowedRecipientsIds: this.news.recipients.allowedRecipientsIds, // currently always "all"
        //       allowedPersons: this.news.recipients.allowedPersons, // currently always []
        //       allowedUserGroups: this.news.recipients.allowedUserGroups, // currently always [{ title: "Alle", id: "all"}]
        //     },
        //   })

        const callFunction = functions.httpsCallable(
          "myOrganization-createNews"
        );
        return callFunction({
          organizationId: this.$route.params.organizationId,

          news: {
            title: this.news.title,
            priority: this.news.priority,
            content: {
              html: this.news.content.html,
            },
            attachments: [],
            contentType: "html", // "html" or "plain-text"
            inputType: "editor", // "external" or "in-app"
            status: "published", // "published" or "draft"
            publishedAt: {
              timestamp: this.news.plannedPublish
                ? this.convertToTimestamp(
                    this.news.publishedAt.date,
                    this.news.publishedAt.time
                  )
                : null,
            },
            publishedBy: {
              uid: auth.currentUser.uid,
              displayName: auth.currentUser.displayName,
            },
            recipients: {
              allowedRecipientsIds: this.news.recipients.allowedRecipientsIds, // currently always "all"
              allowedPersons: this.news.recipients.allowedPersons, // currently always []
              allowedUserGroups: this.news.recipients.allowedUserGroups, // currently always [{ title: "Alle", id: "all"}]
            },
          },
        })
          .then(() => {
            this.$router.push({
              name: "my-organization-news",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          })
          .catch((error) => {
            this.helpers.actionDialog.errorMessage = error.message;
            this.helpers.actionDialog.loading = false;
            console.error("Error creating news: ", error);
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
  },
};
</script>

<style></style>
