<template>
  <div ref="shadowRoot">
    <slot></slot>
  </div>
</template>

<script>
export default {
  mounted() {
    this.attachShadowDom();
  },
  methods: {
    attachShadowDom() {
      const shadowRoot = this.$refs.shadowRoot.attachShadow({ mode: "open" });
      this.$slots.default[0].elm.remove();
      shadowRoot.appendChild(this.$slots.default[0].elm);
    },
  },
};
</script>
